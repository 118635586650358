<template>
  <div class="chart_group" v-loading="loading" element-loading-background="rgba(24,34,45,0.9)">
    <vue-scroll>
      <div class="header">
        <section-title title="图表分组"></section-title>
        <div class="margin-top-20">
          <el-button type="primary" size="small" @click="createGroupDialogVisible = true">新建分组</el-button>
        </div>
        <div class="popover_wrapper margin-top-20">
          <el-popover
            trigger="hover"
            placement="bottom"
            :visible-arrow="false"
            popper-class="my_popover">
            <div class="my_popover_list">
              <div
                :class="['my_popover_list_item', { 'select': curOrder === item.value }]"
                v-for="item in orderList"
                :key="item.value"
                @click="changeOrder(item.value, item.orderBy)">
                <p>{{ item.label }}</p>
              </div>
            </div>
            <p slot="reference" class="text_size_14 text_color_white text_line_height_32 cursor-pointer">
              <span>{{ curOrder | filterOrderValue(orderList) }}</span>
              <i class="el-icon-caret-bottom" style="margin-left: 8px;"></i>
            </p>
          </el-popover>
        </div>
      </div>
      <div class="group_list">
        <div
          v-for="(item, index) in groupList"
          :key="item.groupId"
          :class="['group_list_item', { 'active' : curPopoverId === item.groupId }]"
          @click="toChartList(item)">
          <div class="item_cover flex align_center justify_center">
            <p class="text_color_white">
              <span class="text_size_14">所属图表数：</span>
              <span class="text_size_24">{{ item.chartCount }}</span>
            </p>
          </div>
          <div class="item_footer">
            <div class="flex justify_between align_center text_line_height_30">
              <p class="title text_size_14 text_color_white text_elip" :title="item.groupName ">{{ item.groupName }}</p>
              <el-popover
                @show="curPopoverId = item.groupId"
                @hide="curPopoverId = ''"
                trigger="click"
                placement="bottom"
                :visible-arrow="false"
                popper-class="my_popover">
                <div class="my_popover_list">
                  <div class="my_popover_list_item" @click="openRenameGroupDialog(item)">
                    <i class="iconfont iconbianji"></i>
                    <p>重命名</p>
                  </div>
                  <div class="my_popover_list_item" @click="deleteGroup(item, index)">
                    <i class="iconfont iconshanchu"></i>
                    <p>删除</p>
                  </div>
                </div>
                <div slot="reference" class="icon_wrapper" @click.stop>
                  <i class="iconfont icongongneng text_color_black_10"></i>
                </div>
              </el-popover>
            </div>
            <div class="flex justify_between text_size_12 text_color_secondary text_line_height_30">
              <p>{{ item.changedByUser }}</p>
              <p>{{ item.update_at }}</p>
            </div>
          </div>
        </div>
      </div>
    </vue-scroll>

    <!-- 新建分组 -->
    <create-group
      :visible.sync="createGroupDialogVisible"
      @callback="getChartGroupList">
    </create-group>

    <!-- 重命名分组 -->
    <rename-group
      :visible.sync="renameGroupDialogVisible"
      :group-id="curGroup.groupId"
      :old-name="curGroup.groupName"
      @callback="getChartGroupList">
    </rename-group>
  </div>
</template>

<script>
import * as api from '~/api'
import createGroup from './components/create-group'
import renameGroup from './components/rename-group'
export default {
  data () {
    return {
      loading: false,
      // 新建分组dialog
      createGroupDialogVisible: false,
      // 重命名分组dialog
      renameGroupDialogVisible: false,
      // 当前所选的item的popover
      curPopoverId: '',
      // 当前所选排序规则 默认按 创建时间排序
      curOrder: 'updated_at',
      curOrderBy: 'desc',
      // 当前所选分组Index
      curGroup: {
        groupId: '',
        groupName: ''
      },
      // 排序规则列表
      orderList: [
        {
          label: '按创建时间',
          value: 'created_at',
          orderBy: 'desc'
        },
        {
          label: '按修改时间排序',
          value: 'updated_at',
          orderBy: 'desc'
        },
        {
          label: '按名称排序',
          value: 'title',
          orderBy: 'asc'
        }
      ],
      // 分组列表
      groupList: []
    }
  },
  computed: {
    // 项目id
    projectId () {
      return this.$route.query.projectId || ''
    },
    // 项目标题
    projectName () {
      return decodeURIComponent(this.$route.query.projectName) || ''
    }
  },
  created () {
    // 获取指定项目下的图表分组
    this.getChartGroupList()
  },
  methods: {
    // 获取指定项目下的图表分组
    getChartGroupList () {
      this.loading = true
      api.getChartGroupList({
        projectSpaceId: this.projectId,
        orderWord: this.curOrder,
        orderType: this.curOrderBy
      }).then(res => {
        if (res.data.code === 0) {
          // console.log('图表分组列表', res.data.data)
          this.groupList = res.data.data
        } else {
          this.$message.error(res.data.message)
        }
        this.loading = false
      }).catch(err => {
        this.loading = false
        console.log(err)
        this.$message.error('网络错误，请重试！')
      })
    },

    // 改变排序规则
    changeOrder (value, orderBy) {
      this.curOrder = value
      this.curOrderBy = orderBy
      this.getChartGroupList()
    },

    // 打开重命名dialog
    openRenameGroupDialog (item) {
      this.curGroup = { ...item }
      this.renameGroupDialogVisible = true
    },

    // 删除分组
    deleteGroup (item, index) {
      if (item.chartCount > 0) {
        this.$alert('分组内有图表时无法删除分组！', '无法删除', {
          confirmButtonText: '确定',
          callback: action => {
            this.$message.info('已取消删除！')
          }
        })
      } else {
        this.$msgbox({
          title: '删除分组',
          message: '确定要删除该分组？',
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              this.deleteChartGroup(item, index, instance, done)
            } else {
              done()
            }
          }
        })
      }
    },
    // 删除分组
    deleteChartGroup (item, index, instance, done) {
      instance.confirmButtonLoading = true
      api.deleteChartGroup({
        chartGroupId: item.groupId,
        spaceId: this.projectId
      }).then(res => {
        if (res.data.code === 0) {
          console.log('删除分组成功', res.data.data)
          this.$message.success('删除成功！')

          this.groupList.splice(index, 1)
        } else {
          this.$message.error(res.data.message)
        }
        done()
        instance.confirmButtonLoading = false
      }).catch(err => {
        instance.confirmButtonLoading = false
        console.log(err)
        this.$message.error('网络错误，请重试！')
      })
    },

    // 前往此分组的图表列表
    toChartList (item) {
      this.$router.push({
        path: '/project-detail/chart-list',
        query: {
          listSource: 'chart-group',
          sourceTitle: encodeURIComponent(item.groupName),
          projectId: this.projectId,
          projectName: encodeURIComponent(this.projectName),
          groupId: item.groupId,
          groupType: 'chart_group'
        }
      })
    }
  },
  components: {
    createGroup,
    renameGroup
  },
  filters: {
    // 格式化排序规则
    filterOrderValue (value, list) {
      const obj = list.find(item => {
        return item.value === value
      })
      return obj ? obj.label : ''
    }
  },
  watch: {
    // 切换项目 id 会变化
    projectId () {
      // 获取分组列表
      this.getChartGroupList()
    }
  }
}
</script>

<style lang="scss" scoped>
  .chart_group {
    flex: 1;
    width: 100%;
    height: 100%;
    padding: 0px 30px 0 30px;
    .header {
      display: flex;
      flex-direction: column;
      padding: 30px 10px 0 10px;
      margin-bottom: 20px;
      .popover_wrapper {
        width: 150px;
      }
    }
    .group_list {
      display: flex;
      flex-wrap: wrap;
      .group_list_item {
        position: relative;
        display: flex;
        flex-direction: column;
        width:270px;
        height:200px;
        border:1px solid $border-color-base;
        border-radius:4px;
        margin: 0 10px 20px 10px;
        cursor: pointer;
        background-image: url('../../../../../assets/img/list_item_bg.png');
        background-repeat: no-repeat;
        .item_cover {
          flex: 1;
          border-radius: 4px 4px 0px 0px;
          transition: all 0.3s ease;
        }
        .item_footer {
          flex: none;
          width: 100%;
          height: 79px;
          padding: 10px 20px;
          background-color:rgba(3,13,23,0.5);
          border-radius: 0px 0px 4px 4px;
          .title {
            max-width: 200px;
            transition: all 0.3s ease;
          }
          .icon_wrapper {
            display: none;
            width: 20px;
            height: 20px;
            line-height: 20px;
            text-align: center;
            border-radius:2px;
            &:hover {
              background-color: #424C57;
            }
          }
        }
        &:hover {
          .item_cover {
            background-color: rgba(66,76,87,0.8);
          }
          .item_footer {
            .title {
              color: #6AEBFF;
            }
            .icon_wrapper {
              display: block;
            }
          }
        }
        &.active {
          .item_cover {
            background-color: rgba(66,76,87,0.8);
          }
          .item_footer {
            .title {
              color: #6AEBFF;
            }
            .icon_wrapper {
              display: block;
              background-color: #424C57;
            }
          }
        }
      }
    }
  }
</style>
