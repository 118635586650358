<template>
  <el-dialog
    width="600px"
    :visible.sync="$_visible"
    @close="closeDialog">
    <p slot="title" class="text_size_16 text_color_white text_weight_bold">新建分组</p>
    <el-form
      class="form padding-left-20 padding-right-20"
      ref="form"
      :model="form"
      :rules="rules"
      @submit.native.prevent
      size="small"
      label-position="left"
      label-width="160px"
      hide-required-asterisk>
      <el-form-item label="分组名称" prop="groupName">
        <el-input type="text" v-model="form.groupName" placeholder="请输入分组名称"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button size="small" @click="closeDialog">取消</el-button>
      <el-button type="primary" :loading="saveBtnLoading" size="small" @click="saveForm">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import * as api from '~/api'
export default {
  data () {
    return {
      // 确定按钮loading
      saveBtnLoading: false,
      // 表单对象
      form: {
        groupName: ''
      },
      // 添加目标表达那对象验证
      rules: {
        groupName: [
          { required: true, message: '请输入分组名', trigger: 'blur' }
        ]
      }
    }
  },
  props: {
    // 父组件传来的控制
    visible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    $_visible: {
      get () {
        return this.visible
      },
      set (newValue) {
        this.$emit('update:visible', newValue)
      }
    },
    // 项目id
    projectId () {
      return this.$route.query.projectId || ''
    }
  },
  methods: {
    // 新建分组
    createChartGroup (result) {
      this.saveBtnLoading = true
      api.createChartGroup({
        spaceId: this.projectId,
        ...this.form
      }).then(res => {
        if (res.data.code === 0) {
          // console.log('新建分组成功', res.data.data)
          this.$message.success('新建分组成功')

          this.$emit('callback')
          this.closeDialog()
        } else {
          this.$message.error(res.data.message)
        }
        this.saveBtnLoading = false
      }).catch(err => {
        this.saveBtnLoading = false
        console.log(err)
        this.$message.error('网络错误，请重试！')
      })
    },

    // 保存提交
    saveForm () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.createChartGroup()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 关闭添加表单的dialog
    closeDialog () {
      this.$refs.form.resetFields()
      this.$_visible = false
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
